@import 'styles/theme';

.Register {
  .success {
    display: inline-block;
    margin-top: theme($spacings, 20);
  }

  .error {
    display: inline-block;
    margin-top: theme($spacings, 20);
    line-height: 1.2;

    & + .error {
      margin-top: theme($spacings, 12);
    }
  }
}
