@import 'styles/theme';

.AccountUnloggedWrapper {
  position: relative;

  @include from('md') {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: vw(100);
    min-height: calc(vh(100) - theme($navHeight, 'desktop'));
    padding-top: 38px;
    padding-bottom: 38px;
  }

  .formWrapper {
    position: relative;
    background-color: theme($colors, 'white');
    padding: 40px 16px;

    @include from('md') {
      padding: 80px 56px;
      width: 448px;
    }
  }

  .backgroundImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;

    @include from('md') {
      visibility: visible;
    }
  }

  .title {
    margin-bottom: 16px;
    text-align: center;
  }

  .description {
    text-align: center;
  }

  .children {
    margin-top: 40px;
  }
}
