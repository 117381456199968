@import 'styles/theme';

.Login {
  .loginContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: theme($spacings, 40);

    @include from('md') {
      margin-bottom: theme($spacings, 68);
    }

    .button {
      margin-top: theme($spacings, 10);
      align-self: center;
    }
  }
  .registerContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: theme($spacings, 40);

    .title {
      margin-bottom: 16px;
      text-align: center;
    }

    .description {
      text-align: center;
      margin-bottom: theme($spacings, 16);
    }
  }
}
