@import 'styles/theme';

.Checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline: none;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  box-sizing: border-box;
  border-radius: 1px;
  overflow: hidden;

  .fakeCheckbox {
    pointer-events: none;
    background-color: theme($colors, 'shade-4');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1px;
  }

  input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .checkIcon {
    width: 100%;
    height: 100%;
    display: none;
    color: currentColor;
    pointer-events: none;
  }

  input:checked ~ .fakeCheckbox {
    color: theme($colors, 'black');
    .checkIcon {
      display: block;
    }
  }
}
